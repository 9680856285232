@font-face {
  font-family: 'Montserrat';
  /*a name to be used later*/
  src: url('./fonts/Montserrat-VariableFont_wght.ttf');
  /*URL to font*/
}

* {
  font-family: 'Montserrat' !important;
}

.header {
  background-image: url("./static/banner.jpg");
  background-size: cover;
}